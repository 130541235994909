<template>
  <div class="card">
    <div class="card-content">
      <node-tree
        :key="`tree-${doc.id}`"
        :value="doc.parent"
        :nodes="nodes"
        :parts="[]"
        :options="options"
        @input="updateParent"
      />
    </div>
    <div class="card-footer">
      <div
        class="card-footer-item"
      >
        <b-button
          @click="$parent.close()"
        >
          {{ $t('common.back') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    NodeTree: () => import('@/components/NodeTree')
  },
  computed: {
    ...mapGetters({
      nodes: 'failureNodes/currentFailureItems'
    }),
    options () {
      return {
        canUpdate: true,
        self: this.doc,
        hideParts: true
      }
    }
  },
  methods: {
    updateParent (value) {
      this.$store.dispatch(`failureNodes/set`, {
        id: this.doc.id,
        parent: value
      })
      this.$parent.close()
    },
    async loadNodes() {
      await this.$store.dispatch('currentDoc/loadChildren', {
        id: this.doc.failure.id,
        module: 'failureNodes',
        ref: 'failure.id'
      })

      await this.$store.dispatch('currentDoc/loadChildren', {
        id: this.doc.failure.id,
        module: 'failureParts',
        ref: 'failure.id'
      })
    }
  }
}
</script>
