<template>
  <a-card
    :header="$t('failureNodes.parent.header' , { type: doc.parent && doc.parent.part ? doc.parent.part.name : 'Root'})"
    @startedEditing="loadNodes"
  >
    <template
      #form
    >
      <node-tree
        :value="doc.parent"
        :nodes="nodes"
        :parts="parts"
        :options="options"
        @input="updateParent"
        @hideParts="hideParts"
      />
    </template>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    NodeTree: () => import('@/components/NodeTree')
  },
  computed: {
    ...mapGetters({
      nodes: 'failureNodes/currentFailureItems',
      parts: 'failureParts/currentFailureItems'
    }),
    options () {
      const { hideParts } = this.user.routes.failureNode
      return {
        canUpdate: true,
        self: this.doc,
        hideParts
      }
    }
  },
  created() {
    // console.warn('options', this.options)
    // console.warn('parent', this)
    // console.warn('doc', this.doc)
  },
  methods: {
    updateParent (value) {
      this.$store.dispatch(`failureNodes/set`, {
        id: this.doc.id,
        parent: value
      })
    },
    hideParts (value) {
      this.$store.dispatch('user/update', {
        path: `routes.failureNode.hideParts`,
        value
      })
    },
    async loadNodes() {
      await this.$store.dispatch('currentDoc/loadChildren', {
        id: this.doc.failure.id,
        module: 'failureNodes',
        ref: 'failure.id'
      })

      await this.$store.dispatch('currentDoc/loadChildren', {
        id: this.doc.failure.id,
        module: 'failureParts',
        ref: 'failure.id'
      })
    }
  }
}
</script>
