<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('failures.newNodeOrPart.header') }}
      </p>
    </header>

    <section class="modal-card-body">
      <a-card
        :editable="false"
      >
        <node-tree
          v-model="data.parent"
          :nodes="failureNodes"
          :parts="failureParts"
          :options="options"
          @hideParts="hideParts"
          @duplicate="duplicate"
        />
        <div
          class="buttons"
          :style="{ marginTop: '1em'}"
        >
          <div class="container has-text-centered">
            <b-button
              icon-left="file-tree"
              size="is-large"
              type="is-success"
              @click="create('failureNodes')"
            >
              {{ $t('failures.newNodeOrPart.add_node') }}
            </b-button>

            <b-button
              icon-left="wrench"
              size="is-large"
              type="is-success"
              @click="create('failureParts')"
            >
              {{ $t('failures.newNodeOrPart.add_part') }}
            </b-button>
          </div>
        </div>
      </a-card>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button"
        type="button"
        @click="$parent.close()"
      >
        {{ $t('common.close') }}
      </button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import addDocMixin from '@/components/mixins/addDocMixin'
import NodeTree from '@/components/NodeTree'
import FailureNode from '@/views/FailureNode'

const initialData = {
  parent: null
}

export default {
  components: {
    NodeTree,
    FailureNode
  },
  mixins: [addDocMixin],
  data () {
    return {
      data: _.assign({}, initialData)
    }
  },
  computed: {
    ...mapGetters({
      failureNodes: 'failureNodes/currentFailureItems',
      failureParts: 'failureParts/currentFailureItems'
    }),
    options () {
      const { hideParts } = this.user.routes.failure.newNode
      return {
        canUpdate: true,
        // fullPartsTable: true,
        label: this.$t('failures.newNodeOrPart.select_parent'),
        hideParts
      }
    }
  },
  methods: {
    async duplicate (part) {
      let newPart = _.cloneDeep(part)
      _.unset(newPart, 'id')
      newPart.number = null
      newPart.position = null
      newPart.failureSupply = null
      if(newPart.replacement && newPart.replacement.number) {
        newPart.replacement.number = null
      }
      const id = await this.$store.dispatch(`failureParts/insert`, newPart)
      this.$router.push({ name: 'failurePart', params: { id } })
      this.close()
    },
    async create (module) {
      const self = this
      const newDoc = _.assign({}, this.docProps, {
        parent: this.data.parent ? _.pick(this.data.parent, ['id', 'number', 'part', 'parent']) : null
      })
      const id = await this.$store.dispatch(`${module}/insert`, newDoc)
      if (module === 'failureParts') {
        this.$router.push({ name: 'failurePart', params: { id } })
        this.close()
      } else {

        this.$buefy.modal.open({
          parent: this,
          component: FailureNode,
          hasModalCard: true,
          fullScreen: true,
          canCancel: [],
          props: {
            manualDoc: {
              module: 'failureNodes',
              id
            },
            hasModalCard: true
          },
          events: {
            close (newNode) {
              console.log('newNode', newNode)
              // self.update(newVehicle)
            }
          }
        })
      }
    },
    hideParts (value) {
      this.$store.dispatch('user/update', {
        path: `routes.failure.newNode.hideParts`,
        value
      })
    },
    close () {
      this.$parent.close()
    }
  }
}
</script>

<style scoped>
.modal-card {
    width: auto;
}
.modal-card-body {
    width: auto;
}
</style>
