<style scoped>
  .menu-list li ul {
    margin: 0;
  }

  .menu-list li .node-label {
    margin: 0;
    font-weight: 600;
  }

  /deep/ table.table th {
    color: gray;
    font-weight: 300;
  }

  /deep/ table.table {
    border: 1px solid lightgray;
  }

  /deep/ .hidden-thead thead {
    display: none;
  }

  /deep/ .panel-block {
    font-style: italic;
    font-size: small;
  }
</style>

<template>
  <li
    :key="node.id"
    :class="isSelf(node) ? 'has-background-grey' : $store.getters[`failureNodes/rowClass`](node)"
  >
    <a
      :class="{
        'is-active': Boolean(node.active),
        'is-flex': true,
        'has-inline-children': true
      }"
      :style="disabledStyle(node)"
      @click="selectNode"
    >
      <template v-if="node.id === null">
        <span class="node-label">{{ $t('components.node.root') }}</span>
      </template>
      <template v-else>
        <span
          v-if="options.canUpdate && !options.selectOnly"
          class="is-flex"
        >
          <a
            :title="$t('components.node.edit')"
          >
            <b-icon
              icon="pencil"
              @click.native.stop="editNode(node)"
            />
          </a>
          <a
            :title="$t('components.node.delete')"
          >
            <b-icon
              icon="delete"
              @click.native.stop="removeNode"
            />
          </a>
          <a-select
            field="state"
            :label="null"
            :manual-doc="{ module: 'failureNodes', id: node.id }"
          />
        </span>
        <a
          v-else
          :title="$t('components.node.show')"
        >
          <b-icon
            icon="eye"
            @click.native.stop="editNode(node)"
          />
        </a>
        <span class="node-label">{{ node.part.name }} </span>
        <code v-if="node.number">{{ node.number }}</code>
        <span
          v-if="node.replacement && node.replacement.number"
        >
          <b-icon
            icon="arrow-right"
          />
          <code>{{ node.replacement.number }}</code>
        </span>
      </template>
    </a>

    <template
      v-if="childParts.length && !options.hideParts"
    >
      <div
        v-if="options.selectOnly"
        class="panel"
      >
        <span
          v-for="part in childParts"
          class="panel-block"
          :class="isSelf(part) ? 'has-background-grey' : $store.getters[`failureParts/rowClass`](part)"
          :style="{ filter: 'brightness(110%)' }"
        >
          <span>{{ part.part.name }}</span>
          <code
            v-if="part.number"
            class="my-2 mx-2"
          >{{ part.number }}</code>
          <span
            v-if="part.position"
            class="tag is-black my-2 mx-2"
          >{{ part.position }}</span>
          <span
            v-if="part.replacement"
            class="is-pulled-right my-2 mx-2"
          >
            <b-icon
              icon="arrow-right"
            />
            <span>{{ part.replacement.part.name }}</span>
            <code>{{ part.replacement.number }}</code>
          </span>
        </span>
      </div>
      <a-table
        v-else
        :data="childParts"
        :can-update="options.canUpdate"
        :style="{ filter: 'brightness(110%)' }"
        :columns="user.routes.failure.columns"
        :class="{ 'hidden-thead': options.hideHeaders, 'ml-2': true, 'my-2': true }"
        module-name="failureParts"
        :paginated="false"
      >
        <template
          v-if="options.canUpdate"
          #actions="slotProps"
        >
          <a
            :title="$t('components.node.make_node')"
          >
            <b-icon
              icon="arrow-top-left"
              @click.native="partToNodeDialog(slotProps.props.row)"
            />
          </a>
          <a
            :title="$t('components.node.duplicate')"
          >
            <b-icon
              icon="content-copy"
              @click.native.stop="duplicate(slotProps.props.row)"
            />
          </a>
          <a
            :title="$t('components.node.delete_part')"
          >
            <b-icon
              icon="delete"
              @click.native="removePart(slotProps.props.row)"
            />
          </a>
        </template>
      </a-table>
    </template>

    <ul
      v-if="childNodes.length"
      class="menu-list"
    >
      <node
        v-for="child in childNodes"
        :key="child.id"
        :node="child"
        :nodes="nodes"
        :parts="parts"
        :options="options"
        @select="select"
        @remove="remove"
        @duplicate="duplicate"
      />
    </ul>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import Node from './Node'
import FailureNode from '@/views/FailureNode'
import NodeParent from '@/components/NodeParent'

export default {
  name: 'Node',
  components: {
    Node,
    FailureNode
  },
  props: {
    node: {
      required: true
    },
    nodes: {
      type: Array,
      required: true
    },
    parts: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    childNodes () {
      return this.children('nodes')
    },
    childParts () {
      return this.children('parts')
    }
  },
  methods: {
    partToNodeDialog (failurePart) {
      if (failurePart.state !== 'received' || failurePart.failureSupply) {
        this.$buefy.toast.open({
          duration: 10000,
          message: this.$t('components.node.partToNode.prepare'),
          position: 'is-bottom',
          type: 'is-warning'
        })
      } else {
        this.$buefy.snackbar.open({
          message: this.$t('components.node.partToNode.confirm'),
          type: 'is-warning',
          position: 'is-bottom',
          actionText: 'Yes',
          indefinite: true,
          onAction: () => {
            this.partToNode(failurePart)
          }
        })
      }
    },
    editNode(row) {
      this.$router.push({ name: 'failureNode', params: { id: row.id } })
      /*this.$buefy.modal.open({
        parent: this,
        component: FailureNode,
        hasModalCard: true,
        fullScreen: true,
        canCancel: [],
        props: {
          manualDoc: {
            module: 'failureNodes',
            id: row.id
          },
          hasModalCard: true
        }
      })*/
    },
    editParentNode(row) {
      this.$buefy.modal.open({
        parent: this,
        component: NodeParent,
        hasModalCard: true,
        fullScreen: true,
        // canCancel: [],
        props: {
          manualDoc: {
            module: 'failureNodes',
            id: row.id
          }
        }
      })
    },
    partToNode (failurePart) {
      this.$store.dispatch(`failureNodes/set`, failurePart)
      const id = _.clone(failurePart.id)
      this.$store.dispatch(`failureParts/delete`, id)
    },
    children (items) {
      if (this.node.id === null) {
        return _.filter(this[items], item => {
          return item.parent === null
        })
      } else {
        return _.filter(this[items], item => {
          return item.parent && item.parent.part ? item.parent.id === this.node.id : false
        })
      }
    },
    selectNode () {
      if (this.options.canUpdate && !(this.options.self && (this.options.self.id === this.node.id))) {
        this.$emit('select', this.node)
        console.log('select', this.node)
      }
    },
    removeNode () {
      this.$emit('remove', this.node)
    },
    removePart(failurePart) {
      const self = this
      if (failurePart.state !== 'received' || failurePart.failureSupply) {
        this.$buefy.toast.open({
          duration: 10000,
          message: this.$t('components.node.partToNode.prepare'),
          position: 'is-bottom',
          type: 'is-warning'
        })
      } else {
        this.$buefy.dialog.confirm({
          message: this.$t('components.node.delete.confirm'),
          type: 'is-warning',
          confirmText: 'Delete',
          onConfirm: async () => {
            self.$store.dispatch(`failureParts/delete`, failurePart.id)
          }
        })
      }
    },
    select (parent) {
      this.$emit('select', parent)
    },
    remove (parent) {
      this.$emit('remove', parent)
    },
    duplicate (part) {
      this.$emit('duplicate', part)
    },
    isSelf(node) {
      return this.options.self && (this.options.self.id === node.id)
    },
    disabledStyle(node) {
      if(this.isSelf(node)) {
        return { cursor: 'not-allowed' }
      } else {
        return {}
      }
    }
  }
}
</script>
